:root {
  --textColor: #a9a9a9;
  --mainColor: #011947;
  --headerColor: #ffffff;
  --secondaryColor: #019cde;
}
.whatwedo_container {
  margin: 8rem 6rem;
}
.whatwedo_head p {
  display: flex;
  font-size: 45px;
  font-weight: 500;
  color: var(--mainColor);
}
.whatred_short {
  background-color: var(--secondaryColor);
  width: 0.2rem;
  height: 3rem;
  margin-right: 1rem;
}
.whatred_long {
  background-color: var(--secondaryColor);
  width: 28rem;
  height: 0.2rem;
  margin: 1.5rem 0rem 0 1.3rem;
}
.selection {
  border-radius: 0.3rem;
  width: 20rem;
  border: 1px solid rgb(152, 149, 149);
  margin: 3rem 1.5rem 0 0;
}
.selection:hover {
  cursor: pointer;
  box-shadow: 0rem 1rem 3rem rgba(131, 129, 129, 0.2);
  border: none;
  outline: none;
}
.forcastCard {
  display: flex;
}

.selection img {
  width: 20rem;
  height: 15rem;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.selection_text {
  padding: 1rem;
}
.selection_text h1 {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--secondaryColor);
}

@media only screen and (max-width: 768px) {
  .whatwedo_container {
    margin-left: 0;
    width: 20rem;
  }
  .whatwedo_head {
    margin-left: 1.8rem;
  }
  .whatwedo_head p {
    font-size: 20px;
    margin-top: -5rem;
    margin-left: -1rem;
    width: 18rem;
  }
  .whatred_short {
    background-color: transparent;
  }
  .whatred_long {
    width: 9rem;
    position: relative;
    bottom: 0.4rem;
  }
  .forcastCard {
    display: block;
    width: 20rem;
    margin-left: 1.6rem;
    overflow: hidden;
    overscroll-behavior: none;
  }
}
