
  @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&family=Titillium+Web:wght@300;400;700&display=swap');

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* * {
  outline: 1px solid red !important;
  opacity: 1 !important;
  visibility: visible !important
} */
* {
  
  padding: 0;
  box-sizing: border-box;
  font-family: 'Titillium Web', sans-serif;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
:root {
  --textColor:#a9a9a9;
  --mainColor: #011947;
  --headerColor: #ffffff;
}