:root {
  --textColor: #a9a9a9;
  --mainColor: #011947;
  --secondaryColor: #019cde;
  --headerColor: #ffffff;
}
.home_container {
  margin-bottom: 2rem;
  overflow-x: hidden;
}
.home_construction {
  display: flex;
  background-color: var(--mainColor);
  padding: 10rem 2.5rem;
  margin-bottom: 10rem;
}
.construction_need {
  color: #ffffff;
  width: 35rem;
}
.borderblue_short {
  background-color: var(--secondaryColor);
  height: 8rem;
  width: 0.2rem;
}
.borderblue_long {
  background-color: var(--secondaryColor);
  height: 8rem;
  width: 0.2rem;
}
.monti_ng {
  font-size: 1rem;
  font-weight: 450;
}
.serve_every {
  font-size: 2rem;
  font-weight: 500;
  margin-top: -1rem;
}
.construction_serve {
  background-color: var(--secondaryColor);
  color: var(--headerColor);
  font-size: 2.5rem;
  font-weight: 500;
  padding: 0.5rem 0rem;
  border-radius: 0.5rem;
  margin-top: -1.2rem;
  width: 20rem;
  position: relative;
  text-align: center;
}
.construction_image {
  margin-left: 3rem;
}
/* .construction_image img {
  width: 47rem;
  height: 30rem;
  border-radius: 0.5rem;
} */
.imass_ltd {
  font-size: 1rem;
  font-weight: 500;
}
.ship_drawing img{
  width: 40rem;
  position: relative;
  top: -12rem;
}
.imass_image {
  width: 40rem;
}
.monti_ltd {
  color: var(--secondaryColor);
  font-size: 1rem;
  font-weight: 600;
}
.arrow_right {
  padding-left: 0.5rem;
}
.imass_ship {
  display: flex;
  justify-content: space-between;
  margin: 0 5rem;
}
.vlines {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 90px;
  display: flex;
  /* z-index: -1; */
}
.vlines.vlines--light span {
  border-color: #8b8a8a;
  height: 100%;
}
.vlines.vlines--dashed span {
  border-right-style: dashed;
}
.vlines span {
  flex: 1 1 auto !important;
  height: 100%;
  border-right: 1px solid rgb(212, 24, 24);
}
.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}
.embla__slide img {
  border-radius: 1rem;
  width: 50rem;
}
@media only screen and (max-width: 768px) {
  .home_construction {
    display: block;
    padding-right: 4rem;
    margin: -4rem 0 3rem 0;
  }
  .construction_need {
    width: 19.5rem;
    margin-top: -10rem;
  }
  .construction_image {
    margin-left: 0;
    width: 20rem;
  }
  .borderblue_short {
    background-color: transparent;
  }
  .borderblue_long {
    height: 6rem;
  }
  .imass_ship {
    margin: 0 2rem;
  }
  .embla {
    width: 19rem
  }
}
