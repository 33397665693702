:root {
  --textColor: #a9a9a9;
  --mainColor: #011947;
  --headerColor: #ffffff;
  --secondaryColor: #019cde;
}


body {
  margin: 0;
  padding: 0;
  background-color: #fcfcfc;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  
}

.navbar {
  height: 100px;
  background-color: var(--headerColor);
  padding: 1rem 6rem;
  position: sticky;
  width: 100 !important;
  top: 0;
  z-index: 1020;
  box-shadow: 0rem 0.5rem 3rem rgba(71, 70, 70, 0.2) !important;
}

.logo img {
  width: 8rem;
  height: 8rem;
  padding-top: 0.5rem;
}

.menu-icon {
  display: none;
}
.menu-icon .side_bar {
  position: relative;
  left: 4rem;
}
.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: var(--mainColor);
  text-decoration: none;
  padding: 1rem;
}
.nav-elements a:hover {
  background-color: var(--secondaryColor);
  color: #ffffff;
  padding: 1.2rem;
}
.nav-elements ul a:focus {
  color: var(--secondaryColor);
  font-weight: 550;
  position: relative;
}
.nav-elements ul a:focus:hover {
  color: var(--headerColor);
}
.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

/* //mediaquery // */

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
    cursor: pointer;
    color: var(--secondaryColor)
  }
  .logo img {
    position: relative;
    right: 6rem;
    width: 5rem;
    height: 5rem;
  }
  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: var(--headerColor);
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.2s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }
  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
  .nav-elements a:hover {
    background-color: transparent;
  }
  .nav-elements ul a:focus {
    color: var(--secondaryColor);
    font-weight: 550;
    /* position: relative; */
  }
}
@media (max-width: 1024px) {
  .nav-elements li {
    font-size: 1rem;
  }
}
