:root {
    --textColor:#a9a9a9;
    --mainColor: #011947;
    --headerColor: #ffffff;
    --secondaryColor: #019cde;
}
.our_clients {
    text-align: center;
    margin-bottom: 10rem;
}
.clients_cat {
    color: var(--secondaryColor);
    font-size: 2rem;
    font-weight: 450;
    padding-top: 1rem;
}
.client_logo {
    display: flex;
    justify-content: center;
}

@keyframes pulse {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		-ms-transform:     translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(-30px);
		-ms-transform:     translateY(-30px);
		transform:         translateY(-30px)
	}
	60% {
		-webkit-transform: translateY(-15px);
		-ms-transform:     translateY(-15px);
		transform:         translateY(-15px)
	}
}
.client_logo img {  
  width: 100px;
  margin: 1.5rem;
  text-align: center;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: infinite;
}
.client_logo img:hover {
  cursor: pointer;
  animation-name: pulse;
  -moz-animation-name: pulse;
  animation-duration: 5s;
  -webkit-animation-duration: 1s;
}

.footer_container {
    margin: 5rem;
}
.footer_one {
    background-color: var(--mainColor);
    border-radius: 0.5rem;
    /* margin: 4rem; */
    height: 15rem;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}
.footer_one_img {
    display: flex;
}
.footer_one_img img {
    width: 30rem;
    height: 15rem;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
} 
.start_project {
    display: flex;
    justify-content: space-around;
    padding: 3rem;
}
.start_project p {
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    width: 15rem;
}
.start_next {
    padding-top: 1.5rem;
}
.start_next span {
    color: var(--secondaryColor) !important;
    font-weight: 600 !important;
    font-size: 2.5rem !important;
    position: relative;
    bottom: 2.4rem;
}
.footer_button {
    padding: 3rem;
}
.footer_two {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    background-color: var(--secondaryColor);
    border-radius: 0.5rem;
    margin-top: 2rem;
    font-size: 1rem;
    font-weight: 500;
}
.crafted_span {
    color: var(--mainColor);
}
@media only screen and (max-width: 768px) { 
    .footer_container {
        /* width: 20rem; */
        margin: 0 2.8rem; 
    }
    .our_clients {
        text-align: center;
        font-size: 1rem;
        margin-bottom: 5rem;
    }
    .client_logo {
        justify-content: space-between;
        padding: 0.7rem;
    }
    .client_logo img {
        width: 50px;
        margin: 0.7rem;
    }
    .footerone_container {
        width: 18.5rem;
    }
    .footer_one {
        height: 30rem;
    }
    .footer_one_img {
        display: block;
        
    }
    .footer_one_img img {
        width: 18.5rem;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem !important;
        border-bottom-left-radius: 0rem !important;
    }
    .start_project {
        display: block;
        text-align: center;
        padding: 0 0.5rem;
    }
    .start_next {
        text-align: center;
        position: relative;
        bottom: 1rem;
        padding-left: 1.1rem;
    }
     .footer_button {
        position: relative;
        bottom: 5rem;
    }
    .footer_two {
        display: block;
        font-size: 1rem;
        padding: 1.5rem;
        text-align: center;
        margin-bottom: 2rem;
    }
    
}
