:root {
  --textColor: #a9a9a9;
  --mainColor: #011947;
  --headerColor: #ffffff;
  --secondaryColor: #019cde;
}
.aboutus_image {
  background-color: #ffffff;
}
.imassltd_logo {
  background-image: url("../images/vessel6.jpeg");
  width: 100%;
  height: 25rem;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.black_bg {
  background-color: rgba(10, 10, 10, 0.75);
  height: 25rem;
}
.about_us {
  padding: 0rem 4.5rem;
  margin-top: -19rem;
}
.about_border p {
  display: flex;
  font-size: 45px;
  font-weight: 500;
}
.aboutus_header {
  color: var(--headerColor);
}
.red_border {
  background-color: var(--secondaryColor);
  width: 0.26rem;
  height: 2.7rem;
  margin-right: 1.3rem;
}
.red_borderlong {
  background-color: var(--secondaryColor);
  width: 20rem;
  height: 0.26rem;
  margin: 1.5rem 0rem 0 1.3rem;
}
.aboutus_talk {
  display: flex;
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 4rem 2rem;
  margin-top: -1rem;
}
.aboutus_image img {
  width: 30rem;
}
.vision_container {
  margin: 4rem 3rem;
}
.vision_mission {
  display: flex;
  justify-content: space-evenly;
}
.aboutus_graphic img {
  width: 20rem;
  height: 20rem;
  margin-right: 2rem;
}
.our_vision {
  width: 23rem;
  padding: 1.5rem;
  height: 17rem;
  margin-top: 3rem;
}
.our_vision h1 {
  color: var(--secondaryColor);
  font-size: 2rem;
  font-weight: 400;
}
.our_vision p {
  margin-top: -1rem;
}
.our_vision:hover {
  background-color: #ffffff;
  box-shadow: 0rem 0.5rem 3rem rgba(71, 70, 70, 0.2) !important;
  border-radius: 0.5rem;
}
.our_team {
  margin: 7rem 0;
}
.monti_blue {
  background-color: var(--mainColor);
  color: #ffffff;
  padding: 6rem 2rem 6rem 20rem;
  margin-bottom: 8rem;
}
.monti_blue h3 {
  font-size: 1.5rem;
  font-weight: 400;
}
.monti_blue p {
  font-size: 2rem;
  font-weight: 500;
  margin-top: -2rem;
}
.martime_need {
  color: var(--secondaryColor);
}
.our_team p {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  .red_border {
    background-color: none;
  }
  .red_borderlong {
    width: 15rem;
    position: relative;
    top: 0.9rem;
  }
  .about_us {
    padding: 0 1rem;
  }
  .aboutus_talk {
    display: block;
    padding: 0 2rem;
    /* margin-top: -1rem; */
  }
  .aboutus_imass {
    position: relative;
    top: 18rem;
  }

  .aboutus_image img {
    position: relative;
    bottom: 63rem;
    width: 18rem;
    height: 15rem;
  }
  .vision_mission {
    display: block;
   /* padding: 4rem; */
    text-align: center;
  }
  .our_vision {
    width: 20rem;
    padding: 1rem;
    height: 20rem;
    margin-top: 3rem;
    margin-left: -1.2rem;
}
  .aboutus_graphic img {
    position: relative;
    top: 3rem !important;
  }
  .monti_blue {
    padding: 3rem
  }
  .monti_blue h3 {
    font-size: 1rem;
    font-weight: 500;
  }
  .monti_blue p {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0.2rem;
  }
  .red_border {
    background-color: transparent;
    margin-bottom: 2rem;
  }
  .about_border p {
    font-size: 30px;
  }
  .imassltd_logo {
    background-attachment: fixed;
    background-image: url("../images/vessel6.jpeg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 25rem;
    width: 100%;
  }
}
