:root {
    --textColor:#a9a9a9;
    --mainColor: #011947;
    --headerColor: #ffffff;
    --secondaryColor: #019cde;
}
.contact_us_border {
    padding: 5rem 5rem 0 5rem;
}
.contact_us {
    display: flex;
    justify-content: space-between;
}
.contact_border p {
    display: flex;
    color: var(--mainColor);
    font-size: 45px;
    font-weight: 500;
    /* margin-bottom: 3rem; */
}
.vlines {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 90px;
    display: flex;
    z-index: -1;
    /* margin-top: -25rem; */
}
.vlines.vlines--light span {
    border-color: #8b8a8a;
    height: 100%;
}
.vlines.vlines--dashed span {
    border-right-style: dashed;
}
.vlines span {
    flex: 1 1 auto!important;
    height: 100%;
    border-right: 1px solid #333;
}
.border_green {
    background-color: var(--secondaryColor);
    width: 0.3rem;
    height: 2.8rem;
    margin-right: 2rem;
}
.border_red {
    background-color: var(--secondaryColor);
    width: 15rem;
    height: 0.3rem;
    margin: 1.5rem 0rem 0 2rem;
}

.contact_card {
    background-color: rgb(213, 213, 213);
    border-radius: 1rem;
    width: 25rem;
    height: 30rem;
    padding: 1.5rem;
    box-shadow: 0rem 1rem 3rem rgba(26,2,2,.2)!important;
    margin: -1.8rem 0 0 -1rem;
}

.animate__fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}
.contact_icon {
    display: flex;
}
.contact_icon_awesome {
    padding: 1rem 2rem 0 0;
    color: red;
    height: 30px;
    width: 28px;
    margin-right: -1rem;
}
.contact_office {
    font-size: 1rem;
    font-weight: 500;
}
.contact_address {
    margin-top: -0.7rem;
}
.map_container {
    width: 45rem;
}
.map { 
    width: 50rem;
    height: 27rem; 
    border-radius: 0.5rem;
    z-index: -1;
    padding-top: 1.2rem;
}
@media only screen and (max-width:768px) {
    .contact_us_border {
        padding: 0;
       
    }
    .contact_us {
        display: block;
    }
    .contact_border p{
        font-size: 25px;
    }
    .border_green {
        background-color: transparent;
        
    }
    .border_red {
        width: 11rem;
        position: relative;
    bottom: 0.4rem;
    /* left: 4rem; */
    }
    .contact_us {
        margin-left: 2.1rem;
    }
    .contact_card {
        width: 20rem;
        margin: 0;
    }
.map_container {
    width: 20rem;
    margin: 0;
}
.map { 
    width: 20rem;

}

}